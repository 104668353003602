<template>
  <v-container>
    <h1
      v-intersect="{
        handler: onIntersect,
        options: {
          threshold: [0.95],
        },
      }"
      class="d-flex justify-center"
      style="font-family: 'Garamond Pro'; font-size: 48px"
      @click="pushNotifications()"
    >
      Group List
    </h1>
    <v-container>
      <v-btn
        block
        :color="newGroupEntry ? 'red' : 'green'"
        @click="addNewGroupToggle"
      >
        {{ newGroupEntry ? "Cancel Add New Group " : "Add New Group" }}
      </v-btn>
      <v-spacer class="pb-4 mb-4"></v-spacer>
      <v-text-field v-model="searchValue" label="Search"></v-text-field>
      <v-spacer class="pb-4 mb-4"></v-spacer>
      <v-row dense>
        <v-col v-if="newGroupEntry" cols="12">
          <v-expand-transition>
            <v-card v-show="newGroupEntry" color="#800000" dark>
              <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                  <v-container>
                    <v-text-field
                      v-model="newGroupListData.name"
                      label="Group Name and Dates"
                    ></v-text-field>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="Last Contacted"
                          v-model="newGroupListData.lastContacted"
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <!-- <v-text-field
                          label="Next Contact"
                          v-model="newGroupListData.nextContact"
                          hide-details="auto"
                        ></v-text-field> -->
                        <v-menu
                          v-model="newGroupNextContactModel"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :value="formatDate(newGroupListData.nextContact)"
                              label="Next Contact Date"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="newGroupListData.nextContact"
                            @input="newGroupNextContactModel = false"
                            no-title
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-textarea
                          v-model="newGroupListData.notes"
                          color="teal"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-chip-group
                          v-model="newGroupListData.owner"
                          active-class="dark"
                          column
                        >
                          <v-chip
                            v-for="possibleOwner in owners"
                            :key="possibleOwner"
                          >
                            {{ possibleOwner }}
                          </v-chip>
                        </v-chip-group>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-chip-group
                          v-model="newGroupListData.status"
                          active-class="dark"
                          column
                          multiple
                        >
                          <v-chip v-for="status in statuses" :key="status">
                            {{ status }}
                          </v-chip>
                        </v-chip-group>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-card-actions>
                    <v-btn @click="saveNewGroupEntry()" color="green" block>
                      Save Changes
                    </v-btn>
                  </v-card-actions>
                </div>
              </div>
            </v-card>
          </v-expand-transition>
        </v-col>
        <v-col
          v-for="(group, i) in filteredAndSortedGroupListData"
          :key="i"
          cols="12"
        >
          <v-card :color="i % 2 === 0 ? '#1F7087' : '#3A9087'" dark>
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-container>
                  <v-text-field
                    v-model="group.name"
                    label="Group Name and Dates"
                  ></v-text-field>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Last Contacted"
                        v-model="group.lastContacted"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <!-- <v-text-field
                        label="Next Contact"
                        v-model="group.nextContact"
                        hide-details="auto"
                      ></v-text-field> -->
                      <v-menu
                        v-model="nextContactDateModel[i]"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :value="formatDate(group.nextContact)"
                            label="Next Contact Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="group.nextContact"
                          @input="nextContactDateModel[i] = false"
                          no-title
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-textarea
                        v-model="group.notes"
                        color="teal"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-chip-group
                        v-model="group.owner"
                        active-class="dark"
                        column
                      >
                        <v-chip
                          v-for="possibleOwner in owners"
                          :key="possibleOwner"
                        >
                          {{ possibleOwner }}
                        </v-chip>
                      </v-chip-group>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-chip-group
                        v-model="group.status"
                        active-class="dark"
                        column
                        multiple
                      >
                        <v-chip v-for="status in statuses" :key="status">
                          {{ status }}
                        </v-chip>
                      </v-chip-group>
                    </v-col>
                  </v-row>
                </v-container>
                <v-card-actions>
                  <v-btn
                    @click="saveGroupInfo(group.id, i)"
                    color="green"
                    block
                  >
                    Save Changes
                  </v-btn>
                </v-card-actions>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-spacer class="pb-6 mb-16"></v-spacer>
  </v-container>
</template>
<script>
import moment from "moment";
import { firebaseApp } from "../firebase/init";
import "firebase/firestore";

export default {
  name: "GroupList",
  data: function () {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      newGroupNextContactModel: false,

      searchValue: "",
      newGroupEntry: false,
      snackbar: false,
      text: "Changes Saved",
      timeout: 2000,
      newGroupListData: {
        name: "",
        lastContacted: "",
        nextContact: "",
        notes: "",
        owner: 0,
        status: [0],
      },
      groupListData: {},
      statuses: [
        "Initial Inquiry",
        "Dates Indentified",
        "Contract Issued",
        "Contract Signed",
        "Deposit Recieved",
        "Diets and Room List Received",
        "Diets and Room List Locked",
        "Event Complete",
        "Invoice Issued",
        "Full Payment",
      ],
      owners: ["Kristoph", "Joshua", "Kaylie"],
    };
  },
  computed: {
    filteredAndSortedGroupListData() {
      //let sorted = this.groupListData;
      if (this.searchValue === "") {
        return this.groupListData;
        //return sorted;
      } else {
        return this.groupListData.filter((group) =>
          group.name.includes(this.searchValue)
        );
      }
    },
    nextContactDateModel() {
      let output = [false];
      for (let i = 0; i < this.filteredAndSortedGroupListData.length; i++) {
        output.push(false);
      }
      return output;
    },
    groups() {
      return this.$store.state.groupList;
    },
  },
  methods: {
    formatDate(value) {
      return moment(value).format("dddd, MMMM Do YYYY");
    },
    addNewGroupToggle() {
      if (this.newGroupEntry) {
        this.newGroupEntry = false;
      } else {
        this.newGroupListData = {
          name: "",
          lastContacted: "",
          nextContact: "",
          notes: "",
          owner: 0,
          status: [0],
        };
        this.newGroupEntry = true;
      }
    },
    saveGroupInfo(id, index) {
      firebaseApp
        .firestore()
        .collection("group-list")
        .doc(id)
        .update(this.groupListData[index])
        .then((this.snackbar = true));
    },
    saveNewGroupEntry() {
      firebaseApp
        .firestore()
        .collection("group-list")
        .add(this.newGroupListData)
        .then((docRef) => {
          firebaseApp
            .firestore()
            .collection("group-list")
            .doc(docRef.id)
            .update({ id: docRef.id })
            .then((this.snackbar = true));
        });
      this.newGroupEntry = false;
    },
  },
  firestore: {
    groupListData: firebaseApp
      .firestore()
      .collection("group-list")
      .where("nextContact", ">=", "2022-01-01")
      .orderBy("nextContact", "asc"),
  },
};
</script>
